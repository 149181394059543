export const environment = {
  // Plan Active
  peo_id: "55555",
  token: "592749576",
  key: "592749576",

  // No Plan Exits
  // peo_id: '55555',
  // token: '223366556',
  // key: '223366556',

  // Plan Not Active
  // peo_id: '55555',
  // token: '364447460',
  // key: '364447460',

  production: false,
  apiBaseUrl: "https://ww2staging.slavic401k.com/"
};

