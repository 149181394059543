import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { ParticipantDesignSystemModule, TdsDoughnutWidgetModule, TdsToggleModule } from "participant-design-system";
import { AppRoutingModule } from "./app-routing.modules";
import { UtilitiesService } from "./shared/services/utilities.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ParticipantDesignSystemModule,
    HttpClientModule,
    TdsDoughnutWidgetModule,
    TdsToggleModule,
    AppRoutingModule
  ],
  providers: [UtilitiesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
