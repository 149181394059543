import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Injectable({ providedIn: "root" })
export class UtilitiesService {
  constructor(private router: ActivatedRoute) {
    //
  }

  getQueryParam = (name: string): null | string => {
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(window.location.search);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
    return results[2];
  };

  openTab(link: string,target: string): void {
    window.open(`${link}`, target);
  };
}
